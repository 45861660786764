<template>
<div id="AddCourse">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<!-- add form -->
<v-form @submit.prevent="AddCourse">
<v-container class="pa-5">
<v-card class="pa-2 mt-5 elevation-5">
  <v-card-title>
    <h3>Add Course</h3>
  </v-card-title>
  <v-card-text class="pa-2">
    <v-row>
      <!-- add form -->
      <!-- file upload -->
      <v-col cols="12" sm="12" md="4">
        <v-file-input v-model="files" color="deep-purple accent-4" single-line label="Upload Image"
        prepend-icon="mdi-google-photos" accept="image/*">
        </v-file-input>
      </v-col>
      <!-- other fileds -->
      <v-col cols="12" sm="12" md="4">
          <v-text-field label="Title" :error="$v.Title.$error" :error-messages="TitleErrors"
          v-model.trim="Title" @input="$v.Title.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
          <v-text-field label="Time" :error="$v.Time.$error" :error-messages="TimeErrors"
          v-model.trim="Time" @input="$v.Time.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
          <v-text-field label="Eligibility" style="margin-top: 14px" :error="$v.Eligibility.$error" :error-messages="EligibilityErrors"
          v-model.trim="Eligibility" @input="$v.Eligibility.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
         <v-combobox v-model="Subjects" label="Subjects (Optional)" multiple chips ></v-combobox>
      </v-col>
      <v-col cols="12" sm="12" md="12">
          <v-textarea label="Description"  :error="$v.Description.$error" :error-messages="DescriptionErrors"
          @input="$v.Description.$touch()" v-model="Description" required></v-textarea>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions class="pa-3">
      <v-btn type="submit" class="success" :disabled="disbtn">Add Course</v-btn>
      <v-btn @click="$router.go(-1)" class="secondary">back</v-btn>
  </v-card-actions>

  <!-- show overlay when add item -->
  <v-fade-transition>
  <v-overlay absolute :value="disbtn">
    <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
  </v-overlay>
  </v-fade-transition>

</v-card>

</v-container>
</v-form>

</div>
</template>

<script>
// imports
import { required } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {

    name: 'AddCourse',

    data(){return{
        
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,

        // course flags
        Title: '',Description: '',Time: '',Eligibility: '',Subjects: [],files: [],
    }},

    methods:{

      // add post
      async AddCourse(){
        // vars
        const dateObj = new Date();
        // blank validation
        if(!this.BlankValidation()){return false;}this.disbtn = true;
        // create a unique slug
        let courseslug = this.Slugify(this.Title) + dateObj.getMilliseconds() + '-kalalaya-academy-' +
                        Math.floor(Math.random() * 107477);  + dateObj.getSeconds().toString();
        // Add a new document with a generated id.
        firebase.firestore().collection("courses").add({
            "courseslug": courseslug,"title": this.Title,
            "description": this.Description,"subjects": this.Subjects,
            "time": this.Time,"eligibility": this.Eligibility
        }).then((doc) => {
            // image upload
            this.ImageUpload(doc.id);
        }).catch((err) => {
            // spit out error
            this.disbtn = false;   
            this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
        });

      },

      // image upload function
      ImageUpload(key){

        // vars
        let imageFile;let imageFileName;let ext;let imageUrl;;let task;
        // check if image exists, run Final
        if(this.files.length == 0) { return this.Final(); }
        // actual add file
        imageFile = this.files;
        // get filename
        imageFileName = imageFile.name;
        // get extention of filename
        ext = imageFileName.slice(imageFileName.lastIndexOf('.'));
        // get a reference to store image in firebase storage
        let storageRef = firebase.storage().ref('courses/' + key + ext);
        // upload image
        storageRef.put(imageFile).then((snapshot) => {
          // get download url and update db
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase.firestore().collection('courses').doc(key).update({ imageUrl: downloadURL})
            .then((res) => { this.Final(); });
          });
        }).catch((err) => {
           // spit out error
          this.disbtn = false;   
          this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
        });
   
      },

      // run after all
      Final(){
          this.ResetFields(); 
          this.$store.dispatch('getCourses');
          this.notificationService("Course Added SuccessFully!", "success");
          this.disbtn = false;   
      },

      // reset fields
      ResetFields(){     
        this.Subjects = [];this.Title = '';this.Description = '';
        this.Time = '';this.Eligibility = '';this.files = [];
        this.$v.$reset();
      },

      // check if all fileds are empty   
      BlankValidation(){
        if(this.files){
         if(this.files.size / 1024 >= 2048){this.notificationService("File Should be Under 2MB!", "red darken-1");return false;}
        }
        if(this.files == null || this.files.length == 0 || this.Title == '' || this.Description == '' || this.Time == '' || this.Eligibility == '')
        {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
      },
      
      // error pusher for vuelidate   
      ErrorPusher(basic, extra, msg){
        const errors = []
        if (!basic) return errors
        !extra && errors.push(msg);
        return errors;
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },

      // slugify title (copied from medium :-D)
      Slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
      },

    },

     // validations
    validations: {
        Title: { required }, 
        Description: { required },
        Time: { required },
        Eligibility: { required },
    },

    computed: {
  
      // basically in computed, i added validations  
      // sorry, i cant find a way to make this even smaller : -D
      TitleErrors () { return this.ErrorPusher(this.$v.Title.$dirty, this.$v.Title.required, 'Title is Required'); },
      DescriptionErrors () { return this.ErrorPusher(this.$v.Description.$dirty, this.$v.Description.required, 'Description is Required'); },
      TimeErrors () { return this.ErrorPusher(this.$v.Time.$dirty, this.$v.Time.required, 'Time is Required'); },
      EligibilityErrors () { return this.ErrorPusher(this.$v.Eligibility.$dirty, this.$v.Eligibility.required, 'Eligibility is Required'); },
      
    }

}
</script>
